import short from "short-uuid";
import { toast } from "react-toastify";

const LOCALSTORAGE_DEMO_PORTAL_CONFIG = "ik_demo_portal_configs";

export type DemoPortalConfig = {
  id: string;
  title: string;
  credential: string;
  icon: string;
  sections: SectionConfig[];
};

export type SectionConfig = {
  resource: string;
  label: string;
  icon: string;
};

export const readDemoPortalConfigs = (): DemoPortalConfig[] => {
  const localIngestConfig = localStorage.getItem(
    LOCALSTORAGE_DEMO_PORTAL_CONFIG
  );
  if (!localIngestConfig) {
    return [];
  }

  let parsedConfigs = JSON.parse(
    localIngestConfig ?? ""
  ) as unknown as DemoPortalConfig[];

  if (!parsedConfigs || parsedConfigs.length === 0) {
    parsedConfigs = [createDefaultConfig()];
  }

  return parsedConfigs;
};

export const saveDemoPortalConfigs = (configs: DemoPortalConfig[]) => {
  for (let i = 0; i < configs.length; i++) {
    if (!configs[i].id) {
      configs[i].id = short.generate();
    }
  }

  localStorage.setItem(
    LOCALSTORAGE_DEMO_PORTAL_CONFIG,
    JSON.stringify(configs satisfies DemoPortalConfig[])
  );

  toast("Configuration was saved", {
    type: "success",
    position: "top-right",
  });
};

export const createDefaultConfig = (): DemoPortalConfig => {
  return {
    id: short.generate(),
    title: "",
    credential: "",
    icon: "",
    sections: [
      {
        resource: "",
        label: "",
        icon: "",
      },
    ],
  };
};
