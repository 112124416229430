import { Link } from "react-router-dom";
import {
  readDemoPortalConfigs,
  DemoPortalConfig,
} from "../demo-portal-config/data";
import { useEffect, useState } from "react";
import {
  perfomWhatAuthorized,
  SectionData,
  findHousehold,
  Household,
} from "./data";
import { IdToken, useAuth0 } from "@auth0/auth0-react";

const DemoPortalPage = () => {
  const { user, isAuthenticated, getIdTokenClaims } = useAuth0();
  const configs = readDemoPortalConfigs();
  const [selectedConfigIndex, setSelectedConfigIndex] = useState<number>(0);
  const [config, setConfig] = useState<DemoPortalConfig | undefined>(
    configs[selectedConfigIndex]
  );
  const [sections, setSections] = useState<SectionData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [idToken, setIdToken] = useState<IdToken | undefined>();
  const [household, setHousehold] = useState<Household | undefined>();

  useEffect(() => {
    setLoading(true);
    const config = configs[selectedConfigIndex] || undefined;

    const fetchData = async (config: DemoPortalConfig) => {
      const sections: SectionData[] = await Promise.all(
        config.sections.map(async (section) => {
          return {
            config: section,
            result: await perfomWhatAuthorized({
              credential: config.credential,
              resource: section.resource,
              idToken: idToken?.__raw,
            }),
          };
        })
      );

      const household = await findHousehold({
        credential: config.credential,
        profile: idToken?.profile,
      });

      setSections(sections);
      setHousehold(household);
      setLoading(false);
    };
    if (config && idToken) {
      fetchData(config);
    } else {
      setLoading(false);
    }
    setConfig(config);
  }, [selectedConfigIndex, idToken]);

  useEffect(() => {
    const tokenClaims = async () => {
      const idToken = await getIdTokenClaims();
      setIdToken(idToken);
    };
    if (isAuthenticated) {
      tokenClaims().catch(console.error);
    }
  }, [isAuthenticated, getIdTokenClaims]);

  return (
    <div className="p-6 bg-[#121212] text-white h-full w-full">
      <div className="flex justify-between items-center mb-6">
        <div className="text-4xl font-bold mb-6">Demo Portal</div>
        <div className="flex space-x-4 justify-center items-center">
          {config && (
            <div className="mb-4">
              <select
                id="configSelect"
                value={selectedConfigIndex}
                onChange={(e) => setSelectedConfigIndex(Number(e.target.value))}
                className="p-2 rounded bg-[#1b1b1b] text-white font-raleway font-semibold"
              >
                {configs.map((cfg, index) => (
                  <option key={index} value={index}>
                    {cfg.title}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className="flex space-x-2 mb-4">
            <Link
              to="/demo-portal-config"
              className="no-underline bg-gradient-to-r from-[#554AD5] to-[#6862AD] text-white font-raleway font-semibold px-3 py-2 rounded-md transition-all duration-300"
            >
              Configs
            </Link>
          </div>
        </div>
      </div>

      {!config && (
        <div className="flex justify-center items-center h-full">
          <div className="text-xl font-bold">No configs found</div>
        </div>
      )}
      {config && (
        <div className="p-10 rounded-2xl mt-6 bg-[#1b1b1b]">
          {!loading && (
            <div className="p-6 text-white h-full w-full">
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <img
                    src={config.icon}
                    alt="Icon Preview"
                    className="w-16 h-16 object-cover rounded-md"
                  />
                  <div className="text-3xl">{config.title}</div>
                </div>

                <div>
                  {!isAuthenticated && (
                    <LoginButton credential={config.credential} />
                  )}
                  {isAuthenticated && (
                    <div className="flex items-center gap-2">
                      <div className="text-xl">
                        {idToken?.email || user?.email || "Unknown"}
                      </div>
                      <LogoutButton />
                    </div>
                  )}
                </div>
              </div>

              {!isAuthenticated && (
                <div className="flex justify-center items-center h-full">
                  <div className="text-xl font-bold">
                    Please login to view data
                  </div>
                </div>
              )}
              {isAuthenticated && (
                <div className="flex gap-6 mt-6">
                  <div className="mt-6 grow grid grid-cols-[auto_1fr] gap-4">
                    {sections.map((section, index: number) => (
                      <Section key={index} index={index} data={section} />
                    ))}
                  </div>
                  {household && (
                    <div className="flex flex-col gap-1">
                      <div className="text-2xl">Household</div>

                      <div className="border border-white/30 rounded-lg flex justify-between p-1">
                        {household.name}
                      </div>
                      <div className="border border-white/30 rounded-lg p-2 h-[200px] overflow-y-auto">
                        {household.residents.map((r: string, index: number) => (
                          <div key={index} className="border border-white/30 rounded-lg flex justify-between p-1">
                            {r}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          {loading && (
            <div className="p-6 text-white">
              <div className="flex justify-center items-center">
                <i className="fa-solid fa-circle-notch text-7xl text-white animate-spin"></i>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const Section = ({ index, data }: any) => {
  return (
    <>
      <div className="flex flex-col justify-center items-left ml-10">
        <div className="text-2xl">{data.config.label}</div>
        <img
          src={data.config.icon}
          alt="Icon Preview"
          className="w-16 h-16 object-cover rounded-md"
        />
      </div>
      <div className="border border-white/30 rounded-lg p-2 h-[200px] overflow-y-auto">
        {data.result.length === 0 && (
          <div className="flex text-3xl justify-center items-center h-full">
            No records
          </div>
        )}
        {data.result.length > 0 && (
          <div className="flex flex-col gap-1">
            {data.result.map((a: string, i: number) => (
              <div
                key={i}
                className={`border border-white/30 rounded-lg flex justify-between grow p-1 
                  ${
                    i % 3 === 0
                      ? "odd:bg-[#5c87fa] even:bg-[#5c87fa]/70"
                      : i % 3 === 1
                      ? "odd:bg-[#5db9fa] even:bg-[#5db9fa]/70"
                      : "odd:bg-[#645cfa] even:bg-[#645cfa]/70"
                  }`}
              >
                {a}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

const LoginButton = ({ credential }: any) => {
  const { loginWithRedirect } = useAuth0();
  return (
    <button
      className="flex-shrink bg-gradient-to-r from-[#554AD5] to-[#6862AD] text-white font-raleway font-semibold flex-1 px-3 py-2 rounded-md bg-[#222222] transition-all duration-300"
      onClick={() =>
        loginWithRedirect({
          authorizationParams: {
            redirect_uri: window.location.origin + "/demo-portal",
            agentToken: JSON.parse(credential)?.token,
            baseUrl: JSON.parse(credential)?.baseUrl,
          },
        })
      }
    >
      Log In
    </button>
  );
};

const LogoutButton = () => {
  const { logout } = useAuth0();
  return (
    <button
      className="flex-shrink bg-gradient-to-r from-[#554AD5] to-[#6862AD] text-white font-raleway font-semibold flex-1 px-3 py-2 rounded-md bg-[#222222] transition-all duration-300 max-w-[100px]"
      onClick={() =>
        logout({
          logoutParams: { returnTo: window.location.origin + "/demo-portal" },
        })
      }
    >
      Log Out
    </button>
  );
};

export default DemoPortalPage;
