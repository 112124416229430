import { BrowserRouter, Routes as AllRoutes, Route } from "react-router-dom";
import Index from "./pages/index";
import Configuration from "./pages/configuration";
import Queries from "./pages/queries";
import Insights from "./pages/insights";
import Authorization from "./pages/authorization";
import "react-toastify/dist/ReactToastify.css";
import TrustedDataAccess from "./pages/tda";
import Login from "./pages/login";
import ProfilePicker from "./pages/profile-picker";
import Keycloak from "./pages/keycloak";
import DemoPortal from "./pages/demo-portal";
import DemoPortalConfig from "./pages/demo-portal-config";

const Routes = () => {
  return (
    // <BrowserRouter basename="/pov2">
    <BrowserRouter>
      <AllRoutes>
        <Route path="/insights" element={<Insights />} />
        <Route path="/authorization" element={<Authorization />} />
        <Route path="/tda" element={<TrustedDataAccess />} />
        <Route path="/" element={<Index />} />
        <Route path="/configuration" element={<Configuration />} />
        <Route path="/queries" element={<Queries />} />
        <Route path="/login" element={<Login />} />
        <Route path="/keycloak" element={<Keycloak />} />
        <Route path="/profile-picker" element={<ProfilePicker />} />
        <Route path="/demo-portal" element={<DemoPortal />} />
        <Route path="/demo-portal-config" element={<DemoPortalConfig />} />
      </AllRoutes>
    </BrowserRouter>
  );
};

export default Routes;
