import { toast } from "react-toastify";
import { SectionConfig } from "../demo-portal-config/data";
import { Node, Property } from "../insights/content/data";

export type SectionData = {
  config: SectionConfig;
  result: string[];
};

const WHAT_ENDPOINT =
  "/indykite.authorization.v1beta1.AuthorizationAPI/WhatAuthorized";
const QUERY_ENDPOINT =
  "/indykite.knowledge.v1beta2.IdentityKnowledgeAPI/IdentityKnowledgeRead";

export const perfomWhatAuthorized = async (data: any): Promise<string[]> => {
  try {
    const credential = JSON.parse(data.credential);
    const baseUrl = credential.baseUrl;
    if (!baseUrl) {
      toast("baseUrl not found in credential config", {
        type: "error",
        position: "top-right",
      });
      return [];
    }
    const token = credential.token;
    if (!token) {
      toast("token not found in credential config", {
        type: "error",
        position: "top-right",
      });
      return [];
    }

    const idToken: string = data.idToken;
    if (!idToken) {
      toast("invalid idToken", {
        type: "error",
        position: "top-right",
      });
      return [];
    }

    const profileId = data.profileId || "profileId";

    const payload = {
      subject: {
        accessToken: `${idToken}`,
      },
      resource_types: [
        {
          type: data.resource,
          actions: [`READ`],
        },
      ],
      input_params: {
        profileId: {
          string_value: profileId,
        },
      },
    };

    const url = baseUrl + WHAT_ENDPOINT;
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      const data = await response.json();
      return findExternalIds(data);
    } else {
      throw new Error("Could not read data.");
    }
  } catch (e) {
    console.error(e);
    toast("Error performing authorization", {
      type: "error",
      position: "top-right",
    });
    return [];
  }
};

export interface Household {
  name: string;
  residents: string[];
}

export const findHousehold = async (
  data: any
): Promise<Household | undefined> => {
  const profile = data.profile;
  if (!profile) {
    return undefined;
  }

  try {
    const credential = JSON.parse(data.credential);
    const baseUrl = credential.baseUrl;
    if (!baseUrl) {
      toast("baseUrl not found in credential config", {
        type: "error",
        position: "top-right",
      });
      return undefined;
    }
    const token = credential.token;
    if (!token) {
      toast("token not found in credential config", {
        type: "error",
        position: "top-right",
      });
      return undefined;
    }

    const query = {
      query:
        "MATCH (pro:ConsumerProfile)-[:HAS]->(:Property {type:'name', value:$name}) MATCH (pro)<-[:HAS]-(:Person)-[:LIVES_AT]->(a:Address)<-[:LIVES_AT]-(p:Person)",
      input_params: {
        name: {
          string_value: profile,
        },
      },
      returns: [
        {
          variable: "p",
          properties: ["name"],
        },
        {
          variable: "a",
          properties: ["name"],
        },
      ],
    };

    const url = baseUrl + QUERY_ENDPOINT;
    const request = await fetch(url, {
      method: "POST",
      body: JSON.stringify(query),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const response = await request;
    if (response.status === 200) {
      const data = await response.json();
      const parsedResponse = parseResponse(data);
      if (parsedResponse.length <= 0) {
        return undefined;
      }
      return {
        name: parsedResponse
          .find((n) => n.types.some((t) => t === "Address"))
          ?.properties.find((p) => p.type === "name")?.value,
        residents: parsedResponse
          .filter((n) => n.types.some((t) => t === "Person"))
          .map((n) => n.properties.find((p) => p.type === "name")?.value),
      };
    } else {
      throw new Error("Could not read data.");
    }
  } catch (e) {
    console.error(e);

    toast("Error performing query.", {
      type: "error",
      position: "top-right",
    });
    return undefined;
  }
};

const findExternalIds = (obj: any): string[] => {
  let ids: string[] = [];
  for (let key in obj) {
    if (typeof obj[key] === "object") {
      if (Array.isArray(obj[key])) {
        ids = ids.concat(...obj[key].map((item: any) => findExternalIds(item)));
      } else {
        ids = ids.concat(findExternalIds(obj[key]));
      }
    } else if (key === "externalId") {
      ids.push(obj[key]);
    }
  }
  return ids.sort();
};

const parseResponse = (data: any): Node[] => {
  return (data.nodes || [])
    .map((node: any) => ({
      types: parseTypes(node),
      externalId: node.externalId,
      isIdentity: node.isIdentity,
      properties: parseProperties(node.properties || []),
    }))
    .sort(
      (a: Node, b: Node) =>
        a.types[0].localeCompare(b.types[0]) ||
        a.externalId.localeCompare(b.externalId)
    );
};

const parseTypes = (node: any): string[] => {
  const tags = node.tags || [];
  return [node.type, ...tags];
};

const parseProperties = (properties: any): Property[] => {
  return properties.map((p: any) => ({
    type: p.type,
    value:
      p.value.stringValue ||
      parseInt(p.value.integerValue, 10) ||
      parseFloat(p.value.doubleValue) ||
      p.value.durationValue ||
      p.value.timeValue ||
      (p.value.boolValue || "false").toString(),
  }));
};
