import { Link } from "react-router-dom";
import {
  createDefaultConfig,
  DemoPortalConfig,
  readDemoPortalConfigs,
  saveDemoPortalConfigs,
  SectionConfig,
} from "./data";
import useConfiguration from "../../hooks/useConfiguration";
import { useEffect, useState } from "react";
import useResources, { Node } from "../../hooks/useResources";
import { toast } from "react-toastify";

const DemoPortalConfigPage = () => {
  const { configurations } = useConfiguration();
  const { resources, fetchResources } = useResources();
  const [configs, setConfigs] = useState<DemoPortalConfig[]>([]);
  const [selectedConfigIndex, setSelectedConfigIndex] = useState<number>(0);
  const [resourceTypes, setResourceTypes] = useState<Node[]>([]);

  useEffect(() => {
    const configs = readDemoPortalConfigs();
    setConfigs(configs.length > 0 ? configs : [createDefaultConfig()]);
  }, []);

  useEffect(() => {
    if (configs.length > 0) {
      const selectedConfig = configs[selectedConfigIndex];
      if (!selectedConfig.credential && configurations.length > 0) {
        selectedConfig.credential = configurations[0].value;
      }
      fetchResources(selectedConfig.credential);
    }
  }, [configs, selectedConfigIndex, configurations, fetchResources]);

  useEffect(() => {
    const types = resources
      .filter((n) => n.type !== "Consent")
      .reduce((acc: Node[], curr: Node) => {
        if (acc.find((n) => n.type === curr.type)) {
          return acc;
        }
        return [...acc, curr];
      }, []);
    setResourceTypes(types);
  
    // Update sections with available resources
    if (configs.length > 0) {
      const updatedConfigs = [...configs];
      const selectedConfig = updatedConfigs[selectedConfigIndex];
      const updatedSections = selectedConfig.sections.map((section) => ({
        ...section,
        resource: section.resource || (types.length > 0 ? types[0].type : ""),
      }));
  
      // Check if sections have changed to prevent unnecessary state updates
      const hasChanged = selectedConfig.sections.some((section, index) => 
        section.resource !== updatedSections[index].resource
      );
  
      if (hasChanged) {
        selectedConfig.sections = updatedSections;
        setConfigs(updatedConfigs);
      }
    }
  }, [resources, configs, selectedConfigIndex]);

  const handleCredentialChange = (e: any) => {
    const credential = e.target.value;
    const updatedConfigs = [...configs];
    updatedConfigs[selectedConfigIndex].credential = credential;
    setConfigs(updatedConfigs);
    fetchResources(credential);
  };

  const handleSectionChange = (
    index: number,
    field: keyof SectionConfig,
    value: string
  ) => {
    const updatedConfigs = [...configs];
    const updatedSections = [...updatedConfigs[selectedConfigIndex].sections];
    updatedSections[index] = { ...updatedSections[index], [field]: value };
    updatedConfigs[selectedConfigIndex].sections = updatedSections;
    setConfigs(updatedConfigs);
  };

  const addSection = () => {
    const updatedConfigs = [...configs];
    updatedConfigs[selectedConfigIndex].sections.push({
      resource: resourceTypes.length > 0 ? resourceTypes[0].type : "",
      label: "",
      icon: "",
    });
    setConfigs(updatedConfigs);
  };

  const removeSection = (index: number) => {
    const updatedConfigs = [...configs];
    updatedConfigs[selectedConfigIndex].sections = updatedConfigs[
      selectedConfigIndex
    ].sections.filter((_, i) => i !== index);
    setConfigs(updatedConfigs);
  };

  const addConfig = () => {
    setConfigs([...configs, createDefaultConfig()]);
    setSelectedConfigIndex(configs.length);
  };

  const removeConfig = (index: number) => {
    const updatedConfigs = configs.filter((_, i) => i !== index);
    setConfigs(updatedConfigs);
    setSelectedConfigIndex(0);
  };

  const onSave = (e: any) => {
    e.preventDefault();
    saveDemoPortalConfigs(configs);
  };

  const handleFileUpload = (index: number, file: File) => {
    const MAX_FILE_SIZE = 200 * 1024; // 200 KB

    if (file.size > MAX_FILE_SIZE) {
      toast("File size must be less than 200 KB", {
        type: "error",
        position: "top-right",
      });
      return;
    }
    toast("File is okay", {
      type: "success",
      position: "top-right",
    });

    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result as string;

      const updatedConfigs = [...configs];
      if (index === -42) {
        updatedConfigs[selectedConfigIndex].icon = base64String;
      } else {
        updatedConfigs[selectedConfigIndex].sections[index].icon = base64String;
      }
      setConfigs(updatedConfigs);
    };
    reader.readAsDataURL(file);
  };

  const selectedConfig = configs[selectedConfigIndex];

  return (
    <form onSubmit={onSave}>
      <div className="p-6 bg-[#121212] text-white h-full w-full">
        <div className="text-4xl font-bold mb-6">
          Demo Portal - Configuration Page
        </div>
        <div className="flex space-x-2 mb-4">
          <button
            className="bg-gradient-to-r from-[#554AD5] to-[#6862AD] text-white font-raleway font-semibold px-3 py-2 rounded-md transition-all duration-300"
            onClick={addConfig}
          >
            Add Configuration
          </button>
          <button
            className="bg-gradient-to-r from-[#554AD5] to-[#6862AD] text-white font-raleway font-semibold px-3 py-2 rounded-md transition-all duration-300"
            type="submit"
          >
            Save
          </button>
          <Link
            to="/demo-portal"
            className="no-underline bg-gradient-to-r from-[#554AD5] to-[#6862AD] text-white font-raleway font-semibold px-3 py-2 rounded-md transition-all duration-300"
          >
            Back
          </Link>
        </div>

        {/* Config Tabs */}
        <div className="mb-4">
          {configs.map((config, index) => (
            <div key={index} className="flex items-center mb-2">
              <button
                className={`px-3 py-2 rounded-md font-raleway font-semibold transition-all duration-300 ${
                  index === selectedConfigIndex
                    ? "bg-gradient-to-r from-[#554AD5] to-[#6862AD] text-white"
                    : "bg-[#222222] text-white"
                }`}
                onClick={() => setSelectedConfigIndex(index)}
              >
                {config.title || `Config ${index + 1}`}
              </button>
              {configs.length > 1 && (
                <button
                  className="ml-2 text-red-500"
                  onClick={() => removeConfig(index)}
                >
                  Remove
                </button>
              )}
            </div>
          ))}
        </div>

        {/* Selected Config Form */}
        {selectedConfig && (
          <>
            <div className="pb-4 border-b border-white/30 mb-4">
              <div className="mb-4">
                <label
                  htmlFor="credential"
                  className="text-white font-raleway font-bold"
                >
                  Credential
                </label>
                <select
                  className="rounded-md border-none bg-[#222222] text-white w-full px-3 py-3"
                  id="credential"
                  name="credential"
                  value={selectedConfig.credential}
                  onChange={handleCredentialChange}
                >
                  {configurations.map((t) => (
                    <option key={t.id} value={t.value}>
                      {t.title}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-4">
                <label
                  htmlFor="title"
                  className="text-white font-raleway font-bold"
                >
                  Title
                </label>
                <input
                  className="rounded-md border-none bg-[#222222] text-white w-full px-3 py-3"
                  type="text"
                  id="title"
                  name="title"
                  value={selectedConfig.title}
                  onChange={(e) => {
                    const updatedConfigs = [...configs];
                    updatedConfigs[selectedConfigIndex].title = e.target.value;
                    setConfigs(updatedConfigs);
                  }}
                />
              </div>

              <div>
                <label
                  htmlFor="icon"
                  className="text-white font-raleway font-bold"
                >
                  Title icon
                </label>
                <div className="flex items-center">
                  {selectedConfig.icon && (
                    <div className="mt-2">
                      <img
                        src={selectedConfig.icon}
                        alt="Icon Preview"
                        className="w-16 h-16 object-cover rounded-md mr-2"
                      />
                    </div>
                  )}
                  <div>
                    <input
                      className="rounded-md border-none bg-[#222222] text-white px-3 py-3"
                      type="file"
                      id="icon"
                      name="icon"
                      accept="image/*"
                      onChange={(e: any) =>
                        handleFileUpload(-42, e.target.files[0])
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Sections */}
            <div className="pl-6 pb-4">
              {selectedConfig.sections.map((section, index) => (
                <div key={index} className="mb-4">
                  <Section
                    index={index}
                    data={section}
                    resourceTypes={resourceTypes}
                    onChange={handleSectionChange}
                    onFileUpload={handleFileUpload}
                  />
                  {selectedConfig.sections.length > 1 && (
                    <button
                      type="button"
                      className="bg-gradient-to-r from-[#554AD5] to-[#6862AD] text-white font-raleway font-semibold px-3 py-2 rounded-md transition-all duration-300"
                      onClick={() => removeSection(index)}
                    >
                      Remove Section
                    </button>
                  )}
                </div>
              ))}
            </div>

            {/* Add Section Button */}
            <button
              type="button"
              className="bg-gradient-to-r from-[#554AD5] to-[#6862AD] text-white font-raleway font-semibold px-3 py-2 rounded-md transition-all duration-300"
              onClick={addSection}
            >
              Add Section
            </button>
          </>
        )}
      </div>
    </form>
  );
};

const Section = ({
  index,
  data,
  resourceTypes,
  onChange,
  onFileUpload,
}: any) => {
  return (
    <div className="mb-4">
      <h3 className="text-white font-raleway font-bold">{`Section ${
        index + 1
      }`}</h3>

      <div>
        <label
          htmlFor={`resource-${index}`}
          className="text-white font-raleway font-bold"
        >
          Resource
        </label>
        <select
          className="rounded-md border-none bg-[#222222] text-white w-full px-3 py-3"
          id={`resource-${index}`}
          name={`resource-${index}`}
          value={data.resource}
          onChange={(e) => onChange(index, "resource", e.target.value)}
        >
          {resourceTypes.map((t: any) => (
            <option key={t.type} value={t.type}>
              {t.type}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label
          htmlFor={`sectionLabel-${index}`}
          className="text-white font-raleway font-bold"
        >
          Label
        </label>
        <input
          className="rounded-md border-none bg-[#222222] text-white w-full px-3 py-3"
          type="text"
          id={`sectionLabel-${index}`}
          name={`sectionLabel-${index}`}
          value={data.label}
          onChange={(e) => onChange(index, "label", e.target.value)}
        />
      </div>

      <div>
        <label
          htmlFor={`labelIcon-${index}`}
          className="text-white font-raleway font-bold"
        >
          Label icon
        </label>
        <div className="flex items-center">
          {data.icon && (
            <img
              src={data.icon}
              alt="Icon Preview"
              className="w-16 h-16 object-cover rounded-md mr-2"
            />
          )}
          <input
            className="rounded-md border-none bg-[#222222] text-white px-3 py-3"
            type="file"
            id={`labelIcon-${index}`}
            name={`labelIcon-${index}`}
            accept="image/*"
            onChange={(e: any) => onFileUpload(index, e.target.files[0])}
          />
        </div>
      </div>
    </div>
  );
};

export default DemoPortalConfigPage;
